<template>
  <div class="content">
    <div class="titleWrap">
      <!--            <van-icon name="arrow-left" style="line-height: 42px"/>-->
      <div class="titleText">
        <p>{{ startAddress }}</p>
        <img
          :src="tripType === 'OW' ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/15/yuelvhuiwYaqChQanm1609141435.png'"
          alt=""
        >
        <p>{{ endAddress }}</p>
      </div>
    </div>
    <div class="tickedTimeDetail">
      <div class="AviationInfo">
        <p><span>{{ TravelText }}</span></p>
        <img :src="TravelLogo" alt="" style="margin-left: 28px;margin-top: -5px;">
        <p>{{ TravelTickedText }}{{ TravelCodeText }}</p>
        <p>{{ TimeTickedText }}</p>
        <p>{{ weekTickedText }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ startTime }}</p>
          <p>{{ startAirportText }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ endTime }}</p>
          <p>{{ endAirportText }}</p>
        </div>
      </div>
      <div class="GiftInfo">
        {{ haveMealName }} | {{ planeCode }}
      </div>
    </div>
    <div class="tickedTimePriceDetail">
      <van-tabs v-if="tabList.length > 1" v-model="active" sticky @click="tabClick">
        <van-tab v-for="(item,index) in tabList" :key="index" :title="item.name">
          <div class="tickedList">
            <div
              v-for="(t,tI) in tickedList"
              :key="tI"
              class="tickedListExternal"
              :style="{backgroundImage:'url('+bgc+')'}"
            >
              <div class="tickedListLeft">
                <div class="tickedListLeftTitle">
                  <p class="tickedListPrice">¥<span>{{ t.price }}</span></p>
                  <div>
                    <p>{{ t.prdSubTypeName }}</p>
                    <p>{{ t.prdSubTypeTicketingTime }}</p>
                  </div>
                </div>
                <!--                                儿童票-->
                <div v-if="t.childPrice !== ''" class="isChild">
                  <p><span>儿童票<span class="ChildPrice">¥{{ t.childPrice }}</span></span></p>
                </div>
                <div
                  class="tickedListLeftContent"
                  :class="t.childPrice !== '' ? 'tickedListLeftContent1' :'tickedListLeftContent2'"
                  @click="showNote(t)"
                >
                  <p>
                    <span>{{ t.baseCabinName }}{{ t.discount }}折 | 退改¥{{ (t.refundExp)[0].price }}起 | </span>
                    <span v-if="t.weight === 0">无免费托运行李额</span>
                    <span v-else>托运行李{{ t.weight }}KG</span>
                  </p>
                  <img
                    src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
                    alt=""
                  >
                </div>
              </div>
              <div class="tickedListRight" @click="goReservation(t)">
                <p>预订</p>
                <p><span>剩余{{ t.vote }}张</span></p>
              </div>
            </div>
            <!--                        更多-->
            <div class="tickedListMore" />
          </div>
        </van-tab>
      </van-tabs>
      <div v-else class="tickedList">
        <div
          v-for="(item,index) in tickedList"
          :key="index"
          class="tickedListExternal"
          :style="{backgroundImage:'url('+bgc+')'}"
        >
          <div class="tickedListLeft">
            <div class="tickedListLeftTitle">
              <p class="tickedListPrice">¥<span>{{ item.price }}</span></p>
              <div>
                <p>{{ item.prdSubTypeName }}</p>
                <p>{{ item.prdSubTypeTicketingTime }}</p>
              </div>
            </div>
            <!--                                儿童票-->
            <div v-if="item.childPrice !== ''" class="isChild">
              <p><span>儿童票<span class="ChildPrice">¥{{ item.childPrice }}</span></span></p>
            </div>
            <div
              class="tickedListLeftContent"
              :class="item.childPrice !== '' ? '' :'tickedListLeftContent1'"
              @click="showNote(item)"
            >
              <p>
                <span>{{ item.baseCabinName }}{{ item.discount }}折 | 退改¥{{ (item.refundExp)[0].price }}起 | </span>
                <span v-if="item.weight === 0">无免费托运行李额</span>
                <span v-else>托运行李{{ item.weight }}KG</span>
              </p>
              <img
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
                alt=""
              >
            </div>
          </div>
          <div class="tickedListRight" @click="goReservation(item)">
            <p class="Reservation">预订</p>
            <p class="surplus"><span>剩余{{ item.vote }}张</span></p>
          </div>
        </div>
        <!--                        更多-->
        <div class="tickedListMore" />
      </div>
    </div>

    <!--        展示说明-->
    <van-action-sheet v-model="showDisplayActive">
      <van-tabs v-model="DisplayActive" scrollspy sticky class="DisplayActiveWrap">
        <van-tab title="票价明细" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuix6FjAWywIu1609225209.png"
              alt=""
            >
            <p>票价明细</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ adtPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ chdPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
          </div>
          <div class="TicketRemarks">
            <p>【儿童票】年龄在2周岁（含）-12周岁（不含）的常客，可预订
              儿童票</p>
          </div>
        </van-tab>
        <van-tab title="行李规定" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhui5fqqBtMpBX1609225230.png"
              alt=""
            >
            <p>免费行李规定</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}KG</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="chdBaggage !== undefined" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="退改签说明" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>退订收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>同舱改期收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>签转他航条件</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p style="width: 100%">{{ isChange }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet,
  List,
  Loading,
  Sidebar,
  SidebarItem,
  Tab,
  Tabs,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Tab)
  .use(Tabs)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)
  .use(List)

import {
  getTravelDetail,
  getProductDes
} from '@/services/comeOn.js'

export default {
  name: 'TickedDetail',
  data() {
    return {
      startAddress: '',
      endAddress: '',
      tripType: '',
      TravelText: '单程',
      TravelTickedText: '',
      TravelCodeText: '',
      TimeTickedText: '',
      weekTickedText: '',
      startTime: '',
      startAirportText: '',
      endTime: '',
      endAirportText: '',
      haveMealName: '',
      planeCode: '',
      active: 0,
      tabList: [],
      tickedList: [],
      bgc: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/22/yuelvhuiPcGklV4a6g1609167171.png',
      RemainingTickets: '', // 余票
      showDisplayActive: false,
      DisplayActive: 0,
      CabinName: '',
      adtPrice: '',
      chdPrice: '',
      adtBaggageInfo: [], // 成人行李规定
      chdBaggageInfo: [], // 儿童行李规定
      consign: '',
      portable: '',
      volume: '',
      chdBaggage: '',
      adtRefundFeeList: [],
      chdRefundFeeList: [],
      adtConcessionFeeList: [],
      chdConcessionFeeList: [],
      isChange: '',
      TravelLogo: ''// 航空logo
    }
  },
  created() {

  },
  mounted() {
    this.startAddress = this.$route.query ? this.$route.query.startAddress : this.$route.query.startAddress
    this.endAddress = this.$route.query ? this.$route.query.endAddress : this.$route.query.endAddress
    this.flightinfoid = this.$route.query ? this.$route.query.flightInfoId : this.$route.query.flightInfoId
    this.childNum = this.$route.query ? this.$route.query.childNum : this.$route.query.childNum
    this.tripType = this.$route.query ? this.$route.query.tripType : this.$route.query.tripType
    this.TravelText = this.tripType === 'OW' ? '单程' : '往返'
    this.getTravelDetail()
  },
  methods: {
    getTravelDetail() {
      getTravelDetail({
        flightInfoId: this.flightinfoid, // foid
        childNum: 1, // 儿童票 1/0
        tripType: this.tripType, // 行程类型 OW单程RT往返
        dataExt: {
          rtFlightInfoId: ''// 返程foid
        }
      }).then(res => {
        if (Number(res.code) === 200) {
          const depart = res.data.flightInfo.depart
          this.priceInfo = res.data.priceInfo.depart
          this.TravelLogo = depart.flightCompanyLogo
          this.TravelTickedText = depart.flightCompanyName
          this.TravelCodeText = depart.fn
          this.TimeTickedText = depart.date
          this.weekTickedText = depart.weekDate
          this.startTime = depart.departureDayTime
          this.startAirportText = depart.departureAirport
          this.endTime = depart.arrivalDayTime
          this.endAirportText = depart.arrivalAirport
          this.haveMealName = depart.haveMealName
          this.planeCode = depart.planeCode
          this.oilfee = depart.oilfee// 燃油
          this.tax = depart.tax// 基建
          this.haveMeal = depart.haveMeal// 是否有餐食
          this.departureTerminal = depart.departureTerminal// 出发航站楼
          this.arrivingTerminal = depart.arrivingTerminal// 目的航站楼
          for (let i = 0; i < res.data.priceInfo.depart.length; i++) {
            const tabList = {}
            tabList.number = i
            tabList.name = (res.data.priceInfo.depart)[i].baseCabinName
            this.tabList.push(tabList)
            res.data.priceInfo.depart[i].isShow = false
          }
          // const list = ((res.data.priceInfo.depart)[0].list).unshift((res.data.priceInfo.depart)[0])
          this.tickedList = (res.data.priceInfo.depart)[0].list// 默认展示第一组数据
        }
      })
    },
    // 切换舱位时
    tabClick(name) {
      // const list = (this.priceInfo)[name].list.unshift((this.priceInfo)[name])
      this.tickedList = (this.priceInfo)[name].list
    },
    // 展示说明
    showNote(item) {
      getProductDes({
        flightInfoId: this.flightinfoid,
        tripType: this.tripType,
        priceInfoId: item.priceInfoId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.CabinName = res.data.chd.CabinName// 舱位
          this.adtPrice = res.data.adt.ticketPrice// 成人票价
          this.chdPrice = res.data.chd.ticketPrice// 儿童票价
          this.consign = res.data.adt.luggage.consign// 成人托运行李
          this.portable = res.data.adt.luggage.portable// 成人手提行李
          this.volume = res.data.adt.luggage.volume// 成人手提行李面积
          // 儿童行李标准
          this.chdBaggage = res.data.chd.luggage

          this.adtRefundFeeList = res.data.adt.refundCondition// 成人退票收费list
          this.chdRefundFeeList = res.data.chd.refundCondition// 儿童退票收费标准
          this.adtConcessionFeeList = res.data.adt.rerouteCondition// 成人同舱改期收费规则
          this.chdConcessionFeeList = res.data.chd.rerouteCondition// 儿童同舱改期收费规则
          this.isChange = res.data.chd.transferRule// 改签说明
          this.showDisplayActive = true// 显示产品说明
        }
      })
    },
    // 预订
    goReservation(item) {
      this.$router.push({
        path: '/reservationTicket',
        query: {
          startAddress: this.startAddress,
          endAddress: this.endAddress,
          tripType: this.tripType, // 行程状态
          ticketName: this.TravelTickedText, // 航司名称
          ticketCode: this.TravelCodeText, // 航司码
          DepartureDate: this.TimeTickedText, // 起飞日期
          DepartureWeek: this.weekTickedText, // 起飞周天
          DepartureStartTime: this.startTime, // 起飞开始时间
          DepartureEndTime: this.endTime, // 降落时间
          DepartureAirport: this.startAirportText, // 起飞机场
          LandingAirport: this.endAirportText, // 降落机场
          AdultPrice: item.price, // 成人票价
          ChildrenPrice: item.childPrice, // 儿童票价
          AdultFuelCosts: this.oilfee, // 成人燃油费
          tax: this.tax, // 基建
          baseCabinName: item.baseCabinName, // 舱位类型
          weight: item.weight, // 托运行李额
          discount: item.discount, // 折
          refundExp: (item.refundExp)[0].price, // 退改
          flightInfoId: this.flightinfoid, //
          priceInfoId: item.priceInfoId, //
          haveMeal: this.haveMeal, // 是否有参数
          departureTerminal: this.departureTerminal, // 出发航站楼
          arrivingTerminal: this.arrivingTerminal, // 目的航站楼
          baseCabin: item.baseCabin, // 基础舱位
          luggage: item.luggage, // 行李备注
          cabin: item.cabin// 舱位
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;

  .titleWrap {
    width: 100%;
    height: 42px;
    background-color: #fff;
    text-align: center;
    line-height: 42px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;

    .titleText {
      display: flex;
      line-height: 42px;

      p {
        font-size: 17px;
        color: #333333;
        font-weight: 600;
      }

      img {
        width: 16px;
        height: 5px;
        margin: 17px 8px;
      }

    }
  }

  .tickedTimeDetail {
    padding: 31px 23px;
    background-color: #fff;
    margin-top: 43px;

    .AviationInfo {
      display: flex;

      p {
        line-height: 17px;
        font-size: 13px;
        color: #333333;
      }

      p:nth-child(1) {
        width: 32px;
        height: 17px;
        text-align: center;
        background-color: #E33F44;
        color: #ffffff;
        border-radius: 3px;

        span {
          display: inline-block;
          transform: scale(0.8);
        }
      }

      p:nth-child(2) {
        margin-left: 28px;
      }

      p:nth-child(3) {
        margin-left: 10px;
      }

      p:nth-child(4) {
        margin-left: 10px;
      }
    }

    .TimeInfo {
      display: flex;
      margin-top: 20px;

      .stareTimeInfo, .endTimeInfo {
        color: #333333;
        flex: 1;

        p:nth-child(1) {
          font-size: 29px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 12px;
        }
      }

      .TimeImg {
        flex: 1;

        img {
          width: 52px;
          height: 6px;
          margin: 14px 28px;
        }
      }
    }

    .GiftInfo {
      width: 100%;
      text-align: center;
      margin-top: 17px;
      font-size: 12px;
      color: #999999;
    }
  }

  .tickedTimePriceDetail {
    width: 100%;
    margin-top: 10px;

    .tickedList {
      width: 100%;

      .tickedListExternal {
        width: 100%;
        height: 107px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        padding: 25px 15px;

        .tickedListLeft {
          /*width: 70%;*/
          text-align: left;

          .tickedListLeftTitle {
            display: flex;
            color: #E33F44;
            margin-left: 15px;

            .tickedListPrice {
              font-size: 14px;

              span {
                display: inline-block;
                margin-left: 4px;
                font-size: 23px;
              }
            }

            div {
              margin-left: 4px;
              display: flex;
              margin-top: 5px;

              p {
                margin-left: 6px;
                padding: 0 3px;
                height: 18px;
                line-height: 18px;
                border-radius: 3px;
                border: 1px solid #E33F44;
                color: #E33F44;
                font-size: 12px;
              }
            }
          }

          .isChild {
            margin-top: 7px;
            margin-left: 10px;

            p {
              font-size: 12px;

              span {
                display: inline-block;
                transform: scale(0.9);
              }

              .ChildPrice {
                color: #E33F44;
              }
            }
          }

          .tickedListLeftContent1 {
            margin-top: 9px;
          }

          .tickedListLeftContent2 {
            margin-top: 17px;
          }

          .tickedListLeftContent {
            margin-left: 5px;
            display: flex;

            p {
              color: #333333;

              span {
                display: inline-block;
                transform: scale(0.9);
              }
            }

            img {
              width: 5px;
              height: 9px;
              margin-top: 3px;
            }
          }
        }

        .tickedListRight {
          margin-left: auto;
          border-radius: 3px;
          text-align: center;
          margin-top: 8px;

          .Reservation {
            width: 45px;
            height: 30px;
            background-color: #E33F44;
            color: #ffffff;
            line-height: 35px;
            font-size: 15px;
            border-radius: 3px 3px 0 0;
          }

          .surplus {
            width: 45px;
            height: 15px;
            background-color: #fff;
            color: #E33F44;
            line-height: 15px;
            border: 1px solid #E33F44;
            border-radius: 0 0 3px 3px;
            text-align: left;
            font-size: 12px;

            span {
              display: inline-block;
              transform: scale(0.7);
            }
          }
        }
      }
    }
  }

  .DisplayActiveWrap {
    padding: 16px 20px;

    .DisplayActiveTab {
      padding-top: 16px;
    }

    .TicketTitle {
      display: flex;

      img {
        width: 13px;
        height: 13px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        margin-left: 10px;
      }
    }

    .TicketContent {
      width: 100%;
      margin-top: 11px;
      border-radius: 3px;
      border: 1px solid #E8E8E8;
      text-align: left;

      .TicketContentTitle {
        width: 100%;
        border-radius: 3px 3px 0 0;
        background-color: #f8f8f8;
        font-size: 13px;
        color: #333333;
        padding: 13px 14px;
        font-weight: 500;
      }

      .TicketContentInfo {
        width: 100%;
        display: flex;

        p {
          font-size: 13px;
          padding-left: 14px;
          height: 40px;
          line-height: 40px;
        }

        p:nth-child(1) {
          width: 53px;
          font-weight: 600;
          color: #333333;
        }

        p:nth-child(3), p:nth-child(5) {
          color: #666666;
        }

        p:nth-child(3) {
          width: 155px;
        }

        p:nth-child(5) {
          width: 104px;
        }
      }

      /*行李info*/

      .TicketBaggageContentInfo {
        /*display: flex;*/

        .chdBaggageInfo, .adtBaggageInfo {
          display: flex;

          .chdBaggageInfoT1 {
            width: 149px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }

          .chdBaggageInfoT2 {
            width: 117px;
            line-height: 20px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }
        }

        .chdBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1 {
            line-height: 40px;
          }
        }

        .adtBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1, .chdBaggageInfoT2 {
            line-height: 30px;
          }
        }
      }
    }

    .TicketRemarks {
      width: 100%;
      margin-top: 11px;
      text-align: left;
      line-height: 15px;

      p {
        font-size: 12px;
        color: #666666;
      }
    }

    //退改签info

  }
}
</style>
